import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import './app.css'

const style = {
    height: 500 // we can control scene size by setting container dimensions
};

const dates = [
    {
        date: "10.12.2001",
        title: "Wir haben uns ein Buch gekauft.",
        description: "Wir haben uns ein Buch gekauft. Es ist ein sehr schönes Buch. Wir haben es in der Bibliothek gefunden.",
        id: 1,
        model: "book.obj"
    },
    {
        date: "11.12.2001",
        title: "Fußball mit Leuten gespielt.",
        description: "Wir haben Fußball mit Leuten gespielt. Es war sehr lustig. Wir haben gewonnen.",
        id: 2,
        model: "ball.obj"
    }
]


class App extends Component {
    componentDidMount() {
        this.sceneSetup();
        this.addLights();
        this.loadTheModel("book.obj");
        this.startAnimationLoop();
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.cancelAnimationFrame(this.requestID);
        this.controls.dispose();
    }

    //check if model has changed
    componentDidUpdate(prevProps) {
        if (prevProps.model !== this.props.model) {
            this.updateModel(this.props.model);
        }
    }

    updateModel = (model) => {
        if (!model) return;

        this.scene.remove(this.model);

        //reove all objects from scene
        this.scene.children = [];
        this.addLights();


        this.loadTheModel(model);

    }

    // Standard scene setup in Three.js. Check "Creating a scene" manual for more information
    // https://threejs.org/docs/#manual/en/introduction/Creating-a-scene
    sceneSetup = (model_url) => {
        // get container dimensions and use them for scene sizing
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;

        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(
            75, // fov = field of view
            width / height, // aspect ratio
            0.1, // near plane
            1000 // far plane
        );
        this.camera.position.z = 500; // is used here to set some distance from a cube that is located at z = 0
        // OrbitControls allow a camera to orbit around the object
        // https://threejs.org/docs/#examples/controls/OrbitControls
        this.controls = new OrbitControls(this.camera, this.mount);
        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(width, height);
        this.mount.appendChild(this.renderer.domElement); // mount using React ref
    };

    // Code below is taken from Three.js OBJ Loader example
    // https://threejs.org/docs/#examples/en/loaders/OBJLoader
    loadTheModel = (model_url) => {
        // instantiate a loader
        const loader = new OBJLoader();

        //material loader
        const materialLoader = new MTLLoader();

        materialLoader.load(
            // resource URL
            'book.obj',
            // called when the resource is loaded
            (materials) => {

                materials.preload();

                loader.setMaterials(materials);

                // load a resource
                loader.load(
                    // resource URL relative to the /public/index.html of the app
                    model_url,
                    // called when resource is loaded
                    (object) => {
                        this.scene.add(object);

                        let el = this.scene.getObjectByName("");
                        console.log(el);

                        //auto adjust camera 
                        const box = new THREE.Box3().setFromObject(object);
                        const boxSize = box.getSize(new THREE.Vector3()).length();
                        const boxCenter = box.getCenter(new THREE.Vector3());

                        // set the camera to frame the box
                        this.camera.y = boxCenter.y;
                        this.camera.position.z = boxSize * 1;
                        this.camera.position.x = boxSize * 1;
                        this.camera.position.y = boxSize * 1;
                        this.camera.lookAt(boxCenter);



                        // make this element available inside of the whole component to do any animation later
                        this.model = el;
                    },
                    // called when loading is in progresses
                    (xhr) => {

                        const loadingPercentage = Math.ceil(xhr.loaded / xhr.total * 100);
                        console.log((loadingPercentage) + '% loaded');

                        // update parent react component to display loading percentage
                        this.props.onProgress(loadingPercentage);
                    },
                    // called when loading has errors
                    (error) => {

                        console.log('An error happened:' + error);

                    }
                );

            },
            // called when loading is in progresses
            (xhr) => {

                console.log((xhr.loaded / xhr.total * 100) + '% loaded');

            },
            // called when loading has errors
            (error) => {

                console.log('An error happened');

            }
        );



    };

    // adding some lights to the scene
    addLights = () => {
        const lights = [];

        // set color and intensity of lights
        lights[0] = new THREE.PointLight(0xffffff, 1, 0);
        lights[1] = new THREE.PointLight(0xffffff, 1, 0);
        lights[2] = new THREE.PointLight(0xffffff, 1, 0);

        // place some lights around the scene for best looks and feel
        lights[0].position.set(0, 2000, 0);
        lights[1].position.set(1000, 2000, 1000);
        lights[2].position.set(- 1000, - 2000, - 1000);

        this.scene.add(lights[0]);
        this.scene.add(lights[1]);
        this.scene.add(lights[2]);
    };

    startAnimationLoop = () => {
        // slowly rotate an object
        if (this.model) this.model.rotation.y += 0.005;

        this.renderer.render(this.scene, this.camera);

        // The window.requestAnimationFrame() method tells the browser that you wish to perform
        // an animation and requests that the browser call a specified function
        // to update an animation before the next repaint
        this.requestID = window.requestAnimationFrame(this.startAnimationLoop);
    };

    handleWindowResize = () => {
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;

        this.renderer.setSize(width, height);
        this.camera.aspect = width / height;

        // Note that after making changes to most of camera properties you have to call
        // .updateProjectionMatrix for the changes to take effect.
        this.camera.updateProjectionMatrix();
    };

    render() {
        return <div style={style} ref={ref => (this.mount = ref)} />;
    }
}

class Container extends React.Component {
    state = { isMounted: true };

    setActiveDate = (date) => {

        if (date < 0 || date >= dates.length) return;

        console.log(date);
        this.setState({ activeDate: date })
    }


    render() {
        const { isMounted = true, loadingPercentage = 0, activeDate = 0 } = this.state;

        const date = dates[activeDate];

        return (
            <>
                <div className="timeline">

                    <div className="before-button">
                        <button className="button" onClick={() => this.setActiveDate(activeDate - 1)} >Vorheriges</button>
                    </div>


                    <div className="timeline-item">
                        <div className="timeline-icon"></div>
                        <div className="timeline-content">
                            <h2>{date.date}</h2>
                            <p className="title">{date.title}</p>
                        </div>
                    </div>

                    <div className="next">
                        <button className="button" onClick={() => this.setActiveDate(activeDate + 1)}>Nächstes</button>
                    </div>

                </div>

                {isMounted && <App model={dates[activeDate].model} onProgress={loadingPercentage => this.setState({ loadingPercentage })} />}
                {isMounted && loadingPercentage !== 100 && <div>Model wird geladen: {loadingPercentage}%</div>}

                <div className="subtext">
                    <span>
                        {date.description}
                    </span>

                </div>
            </>
        )
    }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Container />, rootElement);